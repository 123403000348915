import * as React from "react";
import { Link } from "gatsby";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import { withLayout, LayoutProps, menuItems } from "../components/Layout";
import {
  Segment,
  Container,
  Header,
  Table,
  Grid,
} from "semantic-ui-react";

const IndexPage = (props: LayoutProps) =>
  <div>
    {/* Master head */}
    <Segment vertical inverted textAlign="center" className="masthead">
      <HeaderMenu
        Link={Link} pathname={props.location.pathname} items={menuItems} inverted
      />
      <Container text>
        <Header inverted as="h1">Ivan Plamenov Georgiev</Header>
        <Header inverted as="h2">competitive soccer player and soccer enthusiast</Header>
        <img src={require("../../data/avatars/ivan.jpg")} alt="Avatar" />
      </Container>
    </Segment>

    {/* Table with information */}
   <Segment compact attached size="big" className="box">
     <Grid centered columns="4">
       <Grid.Column mobile={16} tablet={8} computer={6}>
    <Table inverted>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Age</Table.Cell>
          <Table.Cell textAlign="right">18</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Height</Table.Cell>
          <Table.Cell textAlign="right">185 cm  (6' 1")</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Weight</Table.Cell>
          <Table.Cell textAlign="right">75 kg  (165 lbs)</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Dominant Foot</Table.Cell>
          <Table.Cell textAlign="right">Left</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Positions</Table.Cell>
          <Table.Cell textAlign="right">Forward/Midfield</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>1 Mile Time</Table.Cell>
          <Table.Cell textAlign="right">-</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>2 Mile Time</Table.Cell>
          <Table.Cell textAlign="right">-</Table.Cell>
        </Table.Row>          
        <Table.Row>
          <Table.Cell>Beep Test</Table.Cell>
          <Table.Cell textAlign="right">-</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
    </Grid.Column>
    </Grid>
    </Segment>
  </div>;

export default withLayout(IndexPage);
